<template>
  <div class="invoiceManagement">
      <el-select v-model="prductPage.type" clearable placeholder="请选择开票类型" @change="find">
        <el-option
          v-for="item in invoiceTypeList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select style="marginLeft:20px" clearable v-model="prductPage.status" placeholder="请选择开票状态" @change="find">
        <el-option
          v-for="item in invoiceStatusList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-button type="primary" icon="el-icon-search" style="marginLeft:20px" @click="find">查询</el-button>

      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; marginTop:20px"
        @selection-change="handleSelectionChange"
        border
        :header-cell-style="{background:'#eef1f6',color:'#606266'}"
        >
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <el-table-column label="申请日期"  prop="applyTime" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.applyTime.substr(0,10)}}</span>
            </template>
          </el-table-column>
          <el-table-column label="公司标识"  prop="companyCode" align="center"></el-table-column>
          <el-table-column label="公司抬头"  prop="invoiceTitle" align="center"></el-table-column>
          <el-table-column label="开票类型" align="center">
              <template slot-scope="scope">
                 <span v-if="scope.row.type == 0">固定月开</span>
                 <span v-else>一次开票</span>
              </template>
          </el-table-column>
          <el-table-column label="开票状态"  prop="status" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.status == 131">审核中</span>
                <span v-if="scope.row.status == 132">已审核</span>
                <span v-if="scope.row.status == 133">已作废</span>
                <span v-if="scope.row.status == 134">已开票</span>
              </template>
          </el-table-column>
          <el-table-column label="开票金额(元)"  prop="invoiceAmount" align="center"></el-table-column>
          <el-table-column label="已开票金额(元)"  prop="hadInvoiceAmount" align="center"></el-table-column>
          <el-table-column label="当前余额"  prop="leftAmount" align="center"></el-table-column>
          <el-table-column align="center" label="操作" width="240px">
            <template slot-scope="scope">
              <div class="operation">
                 <el-button :disabled="scope.row.status==134 || scope.row.status==132 || scope.row.status==133?true:false" size="mini" type="text" @click="checkBtn(scope.row.id, scope.row.status)" icon="el-icon-edit">审核</el-button>
                 <el-button :disabled='scope.row.status==131 || scope.row.status==134 || scope.row.status==133?true:false' size="mini" type="text"  @click="makeInvoiceBtn(scope.row.id, scope.row.status)" icon="el-icon-zoom-in">开票</el-button>
                 <el-button :disabled="scope.row.status==134 || scope.row.status==133?true:false" size="mini" type="text"  @click="invalidBtn(scope.row.id, scope.row.status)" icon="el-icon-circle-close">作废</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block" style="textAlign:right;marginTop:20px;paddingRight:20px">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="prductPage.current"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="prductPage.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      
      <!-- 发票审核 -->
      <el-dialog
        title="发票审核"
        :visible.sync="checkVisible"
        width="23%"
        center
       >
         <p style="text-align: center; color: red; font-size: 16px">该申请是否通过审核?</p>
        <div class="footer">
         <el-button @click="checkVisible = false">取 消</el-button>
         <el-button type="primary" @click="submitCheck">确 定</el-button>
        </div>
      </el-dialog>
      
      <!-- 上传开票附件 -->
      <el-dialog
        title="上传开票附件"
        :visible.sync="makeInvoiceVisible"
        width="25%"
        center
       >
            <!-- accept=".rar .zip .doc .docx .pdf .jpg" -->

          <el-upload
            class="el-upload"
            drag
            ref="uploaded"
            :action="action"
            :file-list="fileList"
            :on-remove='removeFile'
            :on-success="handleSuccess"
            :on-error = "handleError"
            :before-upload= "beforeUpload"
            multiple>
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">点击或将文件拖拽到这里上传</div>
            <!-- <div class="el-upload__tip" style="color: red">支持扩展名: .rar .zip .doc .docx .pdf .jpg...</div> -->
          </el-upload>
        <div class="footer">
         <el-button @click="makeInvoiceVisible = false">取 消</el-button>
         <el-button type="primary" @click="submitFile">确 定</el-button>
        </div>
      </el-dialog>

      <!-- 发票作废 -->
      <el-dialog
        title="发票作废"
        :visible.sync="invalidVisible"
        width="23%"
        center
       >
         <p style="text-align: center; color: red; font-size: 16px">是否作废该申请?</p>
        <div class="footer">
         <el-button @click="invalidVisible = false">取 消</el-button>
         <el-button type="primary" @click="submitInvalid">确 定</el-button>
        </div>
      </el-dialog>

    </div>
</template>

<script>
import { 
  getPageInit, 
  getListPage, 
  invoiceRecord,
  checkRecord,
  invalidRecord
 } from '@/api/InvoiceManage.js'
export default {
    data(){
    return {
      invoiceTypeList: [],
      invoiceStatusList: [],
      total: 0,
      form:{
        type:[],
      },
      fileList: [],
      InvoiceId: "",
      checkId: "",
      invalidId: "",
      checkVisible: false,
      invalidVisible: false,
      makeInvoiceVisible: false,
      tableData: [],
      prductPage: {
        current: 1,
        pageSize: 20,
        type: "",
        status: ""
      }
    };
    },
    created() {
      this.getInit();
      this.search();
    },
    methods:{
      // 查询事件
      search() { 
        let params = {
           current: this.prductPage.current,
           pageSize: this.prductPage.pageSize,
           type: this.prductPage.type,
           status: this.prductPage.status
        }
        getListPage(params).then(res=> {
           if(res.status == 200) {
               this.tableData = [];
              if(res.data.values&&Array.isArray(res.data.values)&&res.data.values.length) {
                this.tableData = res.data.values;
                this.total = res.data.pageInfo.total;  // 获取总条数
              }    
           }
        })
      },
      getInit() {
         getPageInit().then(res => {
            if(res.data.code == 200) {
               if(res.data.data.invoiceStatusList&&Array.isArray(res.data.data.invoiceStatusList)&&res.data.data.invoiceStatusList.length) {
                   this.invoiceStatusList = res.data.data.invoiceStatusList;
               }
               if(res.data.data.invoiceTypeList&&Array.isArray(res.data.data.invoiceTypeList)&&res.data.data.invoiceTypeList.length) {
                  this.invoiceTypeList = res.data.data.invoiceTypeList;
               }
            }
         })
        
      },
      // 审核事件
      checkBtn(id, status) {
        if(status == 131) {
          this.checkId = id;
          this.checkVisible = true;
        }else {
          this.$message.warning('该状态不可以审核');
        }
      },
      // 发票审核
      submitCheck() {
        let data = {
          id: this.checkId,
          status: 132
        }
        checkRecord(data)
        .then(res=> {
          if(res.data.code == 200) {
            this.$message.success(res.data.message);
            this.checkVisible = false;
            this.search();  // 刷新页面
          }else {
            this.$message.error(res.data.message);
          }
        })
      },
      // 作废事件
      invalidBtn(id, status) {
        if(status == 133 || status == 134) {
          this.$message.warning('该状态不可以作废');
          return false;
        }else {
          this.invalidId = id;
          this.invalidVisible = true;
        }
      },
      //  作废确认
      submitInvalid() {
        let data = {
          id: this.invalidId,
          status: 133
        }
        invalidRecord(data)
         .then(res=> {
           if(res.data.code == 200) {
             this.search();
             this.$message.success(res.data.message);
             this.invalidVisible = false;
           }else {
             this.$message.error(res.data.message);
           }
         })
      },
      // 开票事件   
      makeInvoiceBtn(id, status) {
        if(status == 132) {
          this.InvoiceId = id;
          this.fileList = [];
          this.makeInvoiceVisible = true;
        }else {
          this.$message.warning('该状态不可以开票');
        }
      },
      // 开票确认
      submitFile() {
        // if(this.fileList == []) {
        //   this.$message.warning('请先上传开票附件');
        //   return;
        // }else {
          let data = {
            id: this.InvoiceId,
            invoiceImageFileId: this.fileId,
            status: 134
          }
          invoiceRecord(data)
            .then(res=> {
              if(res.data.code == 200) {
                this.search();
                this.$message.success(res.data.message);
                this.makeInvoiceVisible = false;
                this.fileList=[]
                this.fileId=''
              }else {
                this.$message.error(res.data.message);
              }
            })  
          // }
      },
      handleSelectionChange() {

      },
      handleSizeChange(val) {
        this.prductPage.pageSize = val;
        this.search();
      },  
      handleCurrentChange(val) {
        this.prductPage.current = val;
        this.search();
      },
      handleSuccess(response, file, fileList) {
        this.fileList =[file] ;
        this.fileId = response.data.fileId;
        if(response.code == 200) {
          this.$message.success(response.message);
        }else {
          this.$message.error(response.message);
          this.fileList = [];
        }
      },
      removeFile(){
        this.fileList=[]
      },
      handleError(response, file, fileList) {
      },
      beforeUpload(file) {
      },
      find(){
        this.prductPage.current=1
        this.search()
      }
    },
    // 计算属性
    computed: {
       action() {
         return process.env.VUE_APP_API_URL+"/api/file/upload/uploadFile";
       } 
    }
};
</script>

<style scoped lang="scss">
.search {
  display: flex;
  justify-content: flex-end;
}
.operation{
    display: flex;
    justify-content: space-evenly;
}
.newCustomer{
    ::v-deep .el-input__inner{
        width: 270px;
    }
}
.footer {
   text-align: center;
   margin-top: 40px;
}
.el-upload {
   margin-left: 50px;
}
.el-upload-list__item-name {
    color: #606266;
    display: block;
    margin-right: 40px;
    overflow: hidden;
    padding-left: 4px;
    text-overflow: ellipsis;
    transition: color .3s;
}
</style>